:root {
  --brown-dark: #563e2c;
  --brown-medium: #8f6d57;
  --brown-light: #faf6f2;

  --contrast: #ff7300;
  --positive: #5a934c;
  --woolit: #350070;
  --brown-medium-opacity: rgba(143, 109, 87, 0.1);

  --text-color: var(--brown-medium);
  --header-color: var(--brown-dark);

  --header-font-family: "forma-djr-display", sans-serif;
  --small-font-family: "forma-djr-micro", sans-serif;
  --text-font-family: "forma-djr-text", sans-serif;
}

body {
  margin: 0;
  font-family: var(--text-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  border: 1px solid var(--brown-medium-opacity);
  margin: 100px -120px;
}

h1,
h2 {
  color: var(--header-color);
}

h2 {
  font-family: var(--text-font-family);
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 30px;
}

h1 {
  font-family: var(--header-font-family);
  font-size: 2.1em;
  font-weight: 200;
}

p,
em {
  font-family: var(--text-font-family);
  color: var(--text-color);
  margin: 0;
  line-height: 1.3em;
  letter-spacing: 0.03em;
}

button,
select,
input {
  padding: 5px 10px 3px 10px;
  border: 2px solid var(--brown-medium-opacity);
  font-family: var(--small-font-family);
  border-radius: 4px;
  background-color: #fff;
  color: var(--brown-dark);
  font-size: inherit;
  cursor: pointer;
}

button:hover {
  background-color: var(--brown-medium-opacity);
}

a,
a:hover,
a:visited {
  color: var(--contrast);
}

.footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  padding: 24px 24px;
  box-sizing: border-box;
  text-align: center;

  border-top: 1px solid var(--brown-medium-opacity);
  border-bottom: 1px solid var(--brown-medium-opacity);
  background-color: #fff;
}

.toast {
  position: fixed;
  bottom: 120px;
  right: 16%;
  width: 264px;
  border: 4px solid var(--brown-medium-opacity);
  border-radius: 5px;
}
